import { Link } from "gatsby";
import React from "react";
import Products from "../components/categoryPages/Products";
import Seo from "../components/Seo";
import useCategories from "../hooks/useCategories";
import useProducts from "../hooks/useProducts";
import {
  CameraSvg,
  LensSvg,
  MatteboxSvg,
  SpecialityLensSvg,
  ZoomLensSvg,
  FilmCameraSvg,
} from "../images/svgs/productIllustrations";
import {
  getProductsFromCategory,
  getCategoryFromId,
} from "../utils/helperFunctions";
import { categoryHolder } from "./category.module.scss";

export default function CategoryPage({ pageContext: category }) {
  const products = useProducts();
  const allCategories = useCategories();
  const productsInCategory = getProductsFromCategory(
    category.slug,
    products,
    true
  );
  const parentCategory = category.parentId
    ? getCategoryFromId(category.parentId, allCategories)
    : null;

  let Svg = null;

  if (category.slug === "cameras") {
    Svg = CameraSvg;
  } else if (category.slug === "film-cameras") {
    Svg = FilmCameraSvg;
  } else if (category.slug === "lenses") {
    Svg = LensSvg;
  } else if (category.slug === "speciality") {
    Svg = SpecialityLensSvg;
  } else if (category.slug === "zooms") {
    Svg = ZoomLensSvg;
  } else if (category.slug === "accessories") {
    Svg = MatteboxSvg;
  } else if (parentCategory?.slug === "cameras") {
    Svg = CameraSvg;
  } else if (parentCategory?.slug === "lenses") {
    Svg = LensSvg;
  } else if (parentCategory?.slug === "accessories") {
    Svg = MatteboxSvg;
  } else {
    Svg = CameraSvg;
  }

  return (
    <article className={categoryHolder}>
      <div className="svgHolder">
        <Svg />
      </div>
      {parentCategory && (
        <h4>
          <Link
            to={`/category/${parentCategory.slug}`}
            title={parentCategory.title}
          >
            {parentCategory.name}
          </Link>
        </h4>
      )}
      <h1>{category.name}</h1>
      <Products products={productsInCategory} />
    </article>
  );
}

export const Head = ({ pageContext: category }) => (
  <Seo title={`${category.name} - equipment for hire in London`} />
);
